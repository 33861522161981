import React, { useState, useEffect, useRef} from 'react';

const SearchBar = ({ onWordSelect }) => {
    const [query, setQuery] = useState('');
    const [searchText, setSearchText] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        let timerId
        if (query) {
            timerId = setTimeout(() => {fetch(`https://api.datamuse.com/sug?s=${query}`)
                .then(response => response.json())
                .then(response => setSuggestions(response))
                .catch(error => console.error('Error fetching suggestions:', error));
            }, 400)
        } else {
            setSuggestions([]);
        }

        return () => { if (timerId) clearTimeout(timerId) }
    }, [query]);

    // const debounce = useRef((func, delay) => {
    //     let timerId;
    //     return function (...args) {
    //         if (timerId) clearTimeout(timerId);
    //         timerId = setTimeout(() => {
    //             func.apply(this, args);
    //         }, delay);
    //     };
    // }).current;

    // const debouncedSetQuery = useRef(debounce((text) => {
    //     setQuery(text);
    // }, 1000)).current;

    const onItemSelect = (text) => {
        setSearchText(text);
        setSuggestions([]);
        onWordSelect(text);
    };

    const onInputTyped = (text) => {
        setSearchText(text);
        setQuery(text); 
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onWordSelect(searchText);
        }
    };

    return (
        <div className="max-w-80 m-auto relative">
            <input
                className='w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'
                type="text"
                value={searchText}
                onChange={(e) => onInputTyped(e.target.value)}
                onKeyUp={handleKeyPress}
                placeholder="Search for a word..."
            />
            {suggestions?.length > 0 && (
                <ul className="border-2 rounded-md overflow-y-auto absolute left-0 right-0 z-10 bg-white shadow-md max-h-72">
                    {suggestions.map((suggestion, index) => (
                        <li className='px-4 py-2 border-b border-gray-200 cursor-pointer hover:bg-gray-100'
                            key={index}
                            onClick={() => onItemSelect(suggestion.word)}
                        >
                            {suggestion.word}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchBar;
