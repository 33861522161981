// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home'
import Dictionary from './pages/search/Dictionary'
import Welcome from './pages/welcome/Welcome'
// import About from './components/About';
// import NotFound from './components/NotFound';

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Welcome />} />
        <Route path="/stories" element={<Home />} />
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/dictionary" element={<Dictionary />} />
        {/* <Route path="/about" component={About} />
        <Route component={NotFound} /> */}
      </Routes>
    </Router>
  );
}

export default App;
