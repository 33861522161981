import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import capitalizeFirstLetter from '../../../utils/helper'

const WordDetails = ({ word }) => {
  const [details, setDetails] = useState(null);

  useEffect(() => {
    const fetchWordDetails = async () => {
      try {
        if (word) {
          const response = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`);
          if (!response.ok) {
            throw new Error('Failed to fetch word details');
          }
          const data = await response.json();
          setDetails(data[0]); // Assuming you want the details of the first entry
        }
      } catch (error) {
        console.error('Error fetching word details:', error);
      }
    };

    fetchWordDetails();
  }, [word]);

  if (!details) return null;

  return (
    <div className='border m-4 p-4 rounded-md shadow-md bg-white'>
        <div className='flex justify-between mx-10 my-6'>
            <h1 className='text-3xl font-bold'>{capitalizeFirstLetter(details.word)}</h1>


            {details.phonetics.map((phonetic, index) => (
                <div key={index} className='flex items-center'>
                    <p className='text-gray-600 mr-3'>{phonetic.text}</p>
                    {phonetic.audio && <audio controls src={phonetic.audio}></audio>}
                </div>
        ))}
        </div>
      <div className='w-10/12 m-auto'>
      {details.meanings.map((meaning, index) => (
        <div key={index} className='mb-4 flex  border rounded-lg p-10'>
          <h2 className='text-lg font-medium mr-10' >{capitalizeFirstLetter(meaning.partOfSpeech)}</h2>
          <div className='flex flex-col items-start'>
          {meaning.definitions.map((definition, defIndex) => (
            <p key={defIndex} className='mt-2 text-left'>{defIndex+1}. {definition.definition}</p>
          ))}
          </div>
        </div>
      ))}
      </div>
    </div>
  );
};

export default WordDetails;
