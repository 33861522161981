import React, { useState } from 'react';
import SearchBar from './components/SearchBar';
import WordDetails from './components/WordDetails';
import './Dictionary.css';
import { Link } from 'react-router-dom';

const App = () => {
  const [selectedWord, setSelectedWord] = useState('');

  return (
    <div className="App bg-gray-100 min-h-screen">
      {/* Header Section */}
      <header className="bg-gradient-to-r from-blue-500 to-purple-600 shadow-lg">
        <div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <h1 className="text-3xl font-extrabold text-white">Dictionary App</h1>
            <div className="flex items-center space-x-4">
              {/* <button className="text-white hover:text-gray-200 focus:outline-none">
                Settings
              </button> */}
              <Link to='/' className="text-white hover:text-gray-200 focus:outline-none">
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <SearchBar onWordSelect={setSelectedWord} />
        <WordDetails word={selectedWord} />
      </main>
    </div>
  );
};

export default App;

// Credits 
// https://www.datamuse.com/api/
// https://api.dictionaryapi.dev/api/v2/entries/en/apple