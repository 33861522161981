import React from 'react';
import { Link } from 'react-router-dom';

const Welcome = () => {
    return (
        <div className="flex flex-col p-40 h-screen " style={{ backgroundColor: "#cce4ff" }}>
            <div className='flex'>
                <div className='w-full'>
                    <div className="text-left rounded-lg w-1/2">
                        <div className="mb-6">
                            <h2 className="text-3xl font-bold">
                                I'm
                            </h2>
                            <span className="text-6xl text-blue-700 font-bold">
                                Raman Kumar
                            </span>
                        </div>
                        <p className="text-xl text-gray-600">
                            I use JavaScript, C++ and Many more to go...
                        </p>
                    </div>

                    <div className='mt-20'>
                        <h1 className='text-4xl mb-4'>Projects</h1>

                        <div className='flex mt-2 gap-2'>
                            <Link to="/dictionary" className='flex-1'>
                                <div className='border border-blue-300 p-4 rounded-md hover:bg-blue-100 shadow-md hover:shadow-xl h-full'>
                                    <div>
                                        <span className="text-xl font-bold text-gray-800">Dictionary App</span>
                                        <p className="text-sm text-gray-600 mt-2">A web app where you can search words (with autocomplete feature) and see the meaning of words.</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/stories" className='flex-1'>
                                <div className='border border-blue-300 p-4 rounded-md hover:bg-blue-100 shadow-md hover:shadow-xl h-full'>
                                    <div>
                                        <span className="text-xl font-bold text-gray-800">Stories</span>
                                        <p className="text-sm text-gray-600 mt-2">I have written some short stories</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>


                </div>
                <div className='mx-12 w-1 bg-blue-200'>
                    
                </div>
                <div className='w-1/3' >
                    <div className='text-xl text-fuchsia-700'>Open Source Contributions</div>
                </div>
            </div>
        </div>
    );
}

export default Welcome;
